import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection({img1, img2, img3, img4}) {
  const { rpdata } = useContext(GlobalDataContext);

  const heroslide = [
    img1,
    img2,
    img3,
    img4
  ]
  return (
    <>
      <div className="pic-wrapper">
        {
          heroslide.map((item, index) => {
            return (
              <figure
                className={`pic-${index}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }

        <div className="z-[9] relative w-4/5 mx-auto md:h-[80vh] h-[80vh]">
          <div className="w-full h-full pb-[20%] md:pb-[10%] flex flex-col justify-end items-center">
            <h1 className="text-white text-center">
              {rpdata?.dbSlogan?.[7].slogan}
            </h1>
            <p className="text-white text-center"> {rpdata?.dbSlogan?.[8].slogan}</p>
            <div className="flex justify-center md:block">
              <ButtonContent />
            </div>
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
